import { Box } from '@mui/material';
import { useRecoilValue } from 'recoil';

import styles, { mobileViewportSize } from './pageStyles';
import { pinSidebarState } from 'state/atoms';

interface Props {
  children: React.ReactNode;
  sidebarRef: React.MutableRefObject<null>;
  isPerformanceLabPage: boolean;
}

const SidebarWrapper = ({
  children,
  sidebarRef,
  isPerformanceLabPage,
}: Props) => {
  const pinSidebar = useRecoilValue(pinSidebarState);

  return (
    <Box
      ref={sidebarRef}
      sx={{
        ...styles.sidebarWrapper,
        left: isPerformanceLabPage ? undefined : 0,
        [`@media (max-width: ${mobileViewportSize}px)`]: {
          display: pinSidebar ? 'block' : 'none',
        },
      }}
    >
      <Box component="nav" sx={styles.sidebarOverlay}>
        {children}
      </Box>
    </Box>
  );
};

export default SidebarWrapper;
