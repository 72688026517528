import { mainThemeBoldFontFamily } from 'components/MUI/typography';

export const mobileViewportSize = 991;
export const navItemWidth = 270;

export const imageWidth = 24;

const styles = {
  title: {
    display: 'inline-block',
    color: 'white',
    fontFamily: mainThemeBoldFontFamily,
    ml: 2,
    mt: 1,
  },
  logo: {
    display: 'flex',
    p: 2,
    width: '80px',
    height: '62px',
    flexDirection: 'row',
    '& .MuiMenuItem-root': {
      p: 0,
      alignSelf: 'center',
      m: '0 auto',
    },
  },
  submenuLink: {
    px: 6,
    py: 1.5,
    ml: 10,
    fontSize: '14px',
    color: '#BFBFBF !important',
    textDecoration: 'none',
  },
  sidebarWrapper: {
    position: 'fixed',
    width: '80px',
    zIndex: '1030',
    borderRadius: '6px 6px 0 0',
    transition: '0.6s',
    height: '100vh',
    ':hover': {
      '& > nav > ul:nth-of-type(even)': {
        width: `${navItemWidth}px`,
        overflow: 'auto !important',
      },
    },
  },
  sidebarOverlay: {
    '& *': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  buttonHover: {
    '&:hover': {
      color: 'white !important',
    },
  },
  menuItemWrapper: {
    height: 25,
  },
  menuItem: {
    display: 'block',
    minHeight: 24,
    p: 0,
    mb: 2,
    '& .MuiLink-root': {
      py: 2,
      px: 5,
      pl: 7,
      display: 'flex',
      alignItems: 'center',
      '& .MuiBox-root:first-of-type': {
        minWidth: `${imageWidth}px`,
      },
    },
  },
  menuList: {
    height: 'calc(100vh - 71px)',
    mt: 1.2,
    py: 5,
    [`@media (min-width: ${mobileViewportSize}px)`]: {
      pt: 15,
    },
  },
  clubLogoMenuItem: {
    cursor: 'default',
  },
};

export default styles;
