/**
 * Instantiates a single instance PrismaClient and save it on the global object.
 * @link https://www.prisma.io/docs/support/help-articles/nextjs-prisma-client-dev-practices
 */
import { PrismaClient as PrismaClientCC } from 'db/generated/ControlCenter';

export { Prisma as PrismaCC } from 'db/generated/ControlCenter';

import { trace } from '@opentelemetry/api';
import { PrismaClient as PrismaClientCalcey } from 'db/generated/Calcey';

export { Prisma as PrismaCalcey } from 'db/generated/Calcey';

import { env } from 'env.mjs';

import { type DB, db } from 'server/db';

export const prismaClientCCSingleton = () => {
  if (typeof window !== 'undefined') {
    return undefined;
  } else {
    const prismaClientCC = new PrismaClientCC({
      log:
        env.NEXT_PUBLIC_NODE_ENV === 'development'
          ? [
              {
                level: 'query',
                emit: 'event',
              },
              'error',
              'warn',
            ]
          : [
              {
                level: 'query',
                emit: 'event',
              },
              'error',
            ],
    });

    // Commented out because spans not in the same trace as the other Prisma spans
    // prismaClientCC.$on('query', (query) => {
    //   console.log('Query: ' + query.query);
    //   console.log('Params: ' + query.params);
    //   console.log('Duration: ' + query.duration + 'ms');

    //   const span = trace
    //     .getTracer('control-centre')
    //     .startSpan('prisma:cc:meta')
    //     .setAttributes({
    //       query: query.query,
    //       params: query.params,
    //       duration: query.duration,
    //     });

    //   span.end();
    // });

    return prismaClientCC.$extends({
      query: {
        async $allOperations({ operation, model, args, query }) {
          return trace
            .getTracer('prisma')
            .startActiveSpan(
              `prisma:cc:${model}:${operation}`,
              async (span) => {
                span.setAttributes({
                  environment: env.NEXT_PUBLIC_ENVIRONMENT,
                  queryArgs: JSON.stringify(args),
                });

                const result = await query(args);

                span.end();

                return result;
              }
            );
        },
      },
    });
  }
};

export const prismaClientCalceySingleton = () => {
  if (typeof window !== 'undefined') {
    return undefined;
  } else {
    const prismaClientCalcey = new PrismaClientCalcey({
      log:
        env.NEXT_PUBLIC_NODE_ENV === 'development'
          ? [
              {
                level: 'query',
                emit: 'event',
              },
              'error',
              'warn',
            ]
          : [
              {
                level: 'query',
                emit: 'event',
              },
              'error',
            ],
    });

    // Commented out because spans not in the same trace as the other Prisma spans
    // prismaClientCalcey.$on('query', (query) => {
    //   console.log('Query: ' + query.query);
    //   console.log('Params: ' + query.params);
    //   console.log('Duration: ' + query.duration + 'ms');

    //   const span = trace
    //     .getTracer('control-centre')
    //     .startSpan('prisma:calcey:meta')
    //     .setAttributes({
    //       query: query.query,
    //       params: query.params,
    //       duration: query.duration,
    //     });

    //   span.end();
    // });

    return prismaClientCalcey.$extends({
      query: {
        async $allOperations({ operation, model, args, query }) {
          return trace
            .getTracer('prisma')
            .startActiveSpan(
              `prisma:calcey:${model}:${operation}`,
              async (span) => {
                span.setAttributes({
                  environment: env.NEXT_PUBLIC_ENVIRONMENT,
                  queryArgs: JSON.stringify(args),
                });

                const result = await query(args);

                span.end();

                return result;
              }
            );
        },
      },
    });
  }
};

declare global {
  var prismaCC: undefined | ReturnType<typeof prismaClientCCSingleton>;
  var prismaCalcey: undefined | ReturnType<typeof prismaClientCalceySingleton>;
}

/**
 * The Prisma client to use when connecting to the New App Database
 *
 * @deprecated Use the db import from `server/db` instead as it makes more
 * semantic sense. Types can also be accessed as DB from the same file
 */
export const prismaApp = db; // Exported here as to not break import code across the app. Remove once migrated.

/**
 * The types available from the Prisma Client for our Database
 *
 * @deprecated Use the DB import from `server/db` instead as it makes more
 * semantic sense.
 */
export { DB as PrismaApp };

/**
 * The Prisma client to use when connecting to the Startotype Control Centre App Database
 */
export const prismaCC = globalThis.prismaCC ?? prismaClientCCSingleton()!;

/**
 * The Prisma Client to use when connecting to the Calcey Database
 *
 * ⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️
 *
 * We should avoid using this in favour of getting calcey to write API's
 * but it is there when needed.
 *
 * ⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️
 */
export const prismaCalcey_UNSAFE =
  globalThis.prismaCalcey ?? prismaClientCalceySingleton()!;

if (env.NEXT_PUBLIC_NODE_ENV !== 'production') {
  globalThis.prismaCC = prismaCC;
  globalThis.prismaCalcey = prismaCalcey_UNSAFE;
}
