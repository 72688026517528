import { PaletteMode } from '@mui/material';

export const getPageBasedStyles = (
  isPerformanceLabPage: boolean,
  clubColor?: string
) => ({
  wrapper: isPerformanceLabPage ? '#F5F7F9' : clubColor ?? '',
  box: isPerformanceLabPage ? 'white' : clubColor ?? '',
  button: isPerformanceLabPage ? '#595959' : 'white',
  hover: isPerformanceLabPage ? '#F5F7F9' : '#FFFFFF33',
});

export const getIconStyle = (
  theme: PaletteMode,
  isPerformanceLabPage: boolean
) => ({
  color:
    theme === 'light'
      ? getPageBasedStyles(isPerformanceLabPage).button
      : 'white',
  ':hover': {
    backgroundColor:
      theme === 'light'
        ? getPageBasedStyles(isPerformanceLabPage).hover
        : '#FFFFFF33',
  },
});
