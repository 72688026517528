import { TextField, TextFieldProps } from '@mui/material';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo } from 'react';

type Props = {
  onSearchChange: (value: string) => void;
  waitLimit?: number;
} & TextFieldProps;

const TextSearchField = ({
  onSearchChange,
  waitLimit = 500,
  ...others
}: Props) => {
  const handleChange = useCallback(
    (value: string) => {
      onSearchChange(value);
    },
    [onSearchChange]
  );

  const debounceResults = useMemo(() => {
    return debounce(handleChange, waitLimit);
  }, [handleChange, waitLimit]);

  useEffect(() => {
    return () => {
      debounceResults.cancel();
    };
  });

  return (
    <TextField
      {...others}
      onChange={(event) => debounceResults(event.target.value)}
    />
  );
};

export default TextSearchField;
