import { Box } from '@mui/material';
import React from 'react';

interface Props {
  children: React.ReactNode;
  fullScreen?: boolean;
}

const ContainerWrapper = ({ children, fullScreen = false }: Props) => (
  <Box
    sx={{
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      pt: fullScreen ? 0 : 2.5,
      pl: fullScreen ? 0 : 2.5,
    }}
  >
    {children}
  </Box>
);

export default ContainerWrapper;
