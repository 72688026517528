import styles from './pageStyles';
import { PageLink } from './SidebarMenuJSON';
import Link from 'components/MUI/Link';

interface Props {
  pageLink: PageLink;
}

const PageLink = ({ pageLink }: Props) => (
  <Link href={pageLink.link} sx={[styles.submenuLink, styles.buttonHover]}>
    {pageLink.title}
  </Link>
);

export default PageLink;
