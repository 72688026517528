import SearchIcon from '@mui/icons-material/Search';
import { IconButton, useTheme } from '@mui/material';
import { useState } from 'react';

import { getIconStyle } from '../helper';
import EPLPlayerSearchModal from './EplPlayerSearchModal';
import EPLPlayerSessionsModal from './EplPlayerSessionsModal';
import PlayerSearchModal from './PlayerSearchModal';
import { PlayerSearchItem } from 'server/routers/performanceLab/types';

interface Props {
  isPerformanceLabPage: boolean;
}

const PlayerSearch = ({ isPerformanceLabPage }: Props) => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [eplModalOpen, setEplModalOpen] = useState(false);
  const [eplSelectedPlayer, setEplSelectedPlayer] =
    useState<PlayerSearchItem | null>(null);

  const onPlayerSelected = (player: PlayerSearchItem) => {
    setEplSelectedPlayer(player);
    setEplModalOpen(true);
  };

  return (
    <>
      <IconButton
        sx={getIconStyle(theme.palette.mode, isPerformanceLabPage)}
        onClick={() => setOpen(true)}
      >
        <SearchIcon />
      </IconButton>

      {isPerformanceLabPage ? (
        <>
          <EPLPlayerSearchModal
            open={open}
            handleClose={() => setOpen(false)}
            onPlayerSelected={onPlayerSelected}
          />

          {eplSelectedPlayer && (
            <EPLPlayerSessionsModal
              open={eplModalOpen}
              handleClose={() => setEplModalOpen(false)}
              selectedPlayer={eplSelectedPlayer}
            />
          )}
        </>
      ) : (
        <PlayerSearchModal open={open} handleClose={() => setOpen(false)} />
      )}
    </>
  );
};

export default PlayerSearch;
