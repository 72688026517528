import Router from 'next/router';

import { fallbackLocale } from './getRegionalDate';
import { V2PositionsQueriesListModelsPositionModel } from 'orval/calceyAPI/reactQuery';

const nonUSTerm = 'Centre';
const usTerm = 'Center';

export const getPlayerPositionsByRegion = (
  positionList: V2PositionsQueriesListModelsPositionModel[]
) => {
  const locale = Router.locale ?? fallbackLocale;

  if (locale === fallbackLocale) return positionList;

  const newList =
    positionList?.map((position) => {
      if (position.name?.includes(nonUSTerm)) {
        return {
          ...position,
          name: position.name.replaceAll(nonUSTerm, usTerm) ?? '',
        };
      }

      return position;
    }) ?? [];

  return newList;
};

export const getPlayerPositionByRegion = (
  position: string | undefined | null
) => {
  const locale = Router.locale ?? fallbackLocale;

  if (!position) return '';

  if (locale === fallbackLocale) return position;

  return position.replaceAll(nonUSTerm, usTerm);
};
