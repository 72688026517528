import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Box,
  Collapse,
  Link as MuiLink,
  MenuItem,
  MenuList,
} from '@mui/material';
import { env } from 'env.mjs';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import ClubLogo from './ClubLogo';
import { getUserRoleById, isLocalhost, isUpcomingPage } from './pageHelpers';
import PageLink from './PageLink';
import styles, {
  imageWidth,
  mobileViewportSize,
  navItemWidth,
} from './pageStyles';
import SidebarMenuJSON from './SidebarMenuJSON';
import SidebarWrapper from './SidebarWrapper';
import { castActiveState, pinSidebarState } from 'state/atoms';
import useClickOutside from 'hooks/useClickOutside';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { getPerformanceLabPagePaths } from 'helpers/getPerformanceLabPages';
import { trpc } from 'helpers/trpc';

const Sidebar = () => {
  const router = useRouter();
  const sidebarRef = useRef(null);
  const [pinSidebar, setPinSidebar] = useRecoilState(pinSidebarState);
  const castActive = useRecoilValue(castActiveState);

  const { data: currentUser } = trpc.shared.currentUser.useQuery();

  const [activeItemName, setActiveItemName] = useState<null | string>(null);

  const currentUserRoleId = currentUser?.user.roleId;
  const currentPathname = router.pathname;

  const isPerformanceLabPage =
    getPerformanceLabPagePaths().includes(currentPathname);

  const { width } = useWindowDimensions();

  const close = () => {
    if (width < mobileViewportSize && pinSidebar) {
      setPinSidebar(false);
    }
  };
  useClickOutside(sidebarRef, close);

  const homeMenuItem = (
    <MenuItem sx={styles.menuItem}>
      <MuiLink href="/">
        <Box sx={styles.menuItemWrapper}>
          <Image
            src="/images/sidebar/home.svg"
            alt="me"
            width={imageWidth}
            height="24"
          />
          <Box component="span" sx={styles.title}>
            Home
          </Box>
        </Box>
      </MuiLink>
    </MenuItem>
  );

  const sidebarMenuUI = SidebarMenuJSON.filter(({ roles }) => {
    if (roles === 'All') return true;

    return getUserRoleById(roles, currentUserRoleId);
  })
    .filter((pageLink) => {
      if (pageLink.turkGroup === undefined) {
        return true;
      }

      return currentUser?.user.groups.includes('Turk');
    })
    .filter((item) => {
      const isOnStage = env.NEXT_PUBLIC_ENVIRONMENT === 'staging';

      const developerMode = isLocalhost() || isOnStage;

      if (item.forDevs && !developerMode) {
        return false;
      }

      return true;
    })
    .filter((item) => {
      if (item.clubsVisibleList && currentUser) {
        return item.clubsVisibleList.includes(currentUser.club.activeClubId);
      }

      return true;
    })
    .map((item) => (
      <Box key={item.title}>
        <MenuItem
          key={item.title}
          onClick={() => {
            if (activeItemName === item.title) {
              setActiveItemName(null);
            } else {
              setActiveItemName(item.title);
            }
          }}
          sx={styles.menuItem}
        >
          <MuiLink sx={{ verticalAlign: 'middle' }}>
            <Box sx={styles.menuItemWrapper}>
              <Image src={item.image} alt="me" width={imageWidth} height="24" />
              <Box component="span" sx={styles.title}>
                {item.title}
              </Box>
            </Box>
            <Box sx={styles.menuItemWrapper}>
              <ChevronRightIcon
                sx={{
                  transform: `rotate(${
                    activeItemName === item.title ? '90deg' : '0deg'
                  })`,
                  mr: 2,
                  color: '#BFBFBF',
                  ...styles.buttonHover,
                }}
              />
            </Box>
          </MuiLink>
        </MenuItem>
        <Collapse in={activeItemName === item.title} orientation="vertical">
          <MenuList sx={{ p: 0 }}>
            {SidebarMenuJSON.find(({ title }) => title === item.title)!
              .submenu.filter(({ roles }) => {
                if (roles === 'All') return true;

                return getUserRoleById(roles, currentUserRoleId);
              })
              .filter((pageLink) => pageLink.invisibleLink !== true)
              .filter((pageLink) => {
                if (pageLink.turkGroup === undefined) {
                  return true;
                }

                return currentUser?.user.groups.includes(pageLink.turkGroup);
              })
              .filter((pageLink) => {
                if (pageLink.clubsVisibleList && currentUser) {
                  return pageLink.clubsVisibleList.includes(
                    currentUser.club.activeClubId
                  );
                }

                return true;
              })
              .map((pageLink) => (
                <MenuItem
                  key={pageLink.title}
                  onClick={(event) => {
                    if (isUpcomingPage(pageLink)) {
                      event.preventDefault();
                      event.stopPropagation();

                      return;
                    }

                    setPinSidebar(false);
                  }}
                  sx={{
                    p: 0,
                    ':hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                  disableTouchRipple={true}
                >
                  <PageLink pageLink={pageLink} />
                </MenuItem>
              ))}
          </MenuList>
        </Collapse>
      </Box>
    ));

  const onMouseLeave = () => {
    if (!pinSidebar) {
      setActiveItemName(null);
    }
  };

  const menuListStyles = [
    pinSidebar && {
      width: `${navItemWidth}px`,
    },
    {
      background: `${currentUser?.club.color}`,
      ...styles.menuList,
    },
  ];

  useEffect(() => {
    if (!pinSidebar) {
      setActiveItemName(null);
    }
  }, [pinSidebar]);

  if (castActive) {
    return null;
  }

  return (
    <SidebarWrapper
      sidebarRef={sidebarRef}
      isPerformanceLabPage={isPerformanceLabPage}
    >
      <ClubLogo
        currentUser={currentUser!}
        isPerformanceLabPage={isPerformanceLabPage}
      />
      <MenuList sx={menuListStyles} onMouseLeave={onMouseLeave}>
        {homeMenuItem}
        {sidebarMenuUI}
      </MenuList>
    </SidebarWrapper>
  );
};

export default Sidebar;
