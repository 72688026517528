const convertToSentenceCase = (name: string) => {
  const formattedSentence = name
    .replace(/([A-Z])/g, ' $1')
    .trim()
    .toLocaleLowerCase();

  return formattedSentence.charAt(0).toUpperCase() + formattedSentence.slice(1);
};

export default convertToSentenceCase;
