import { MenuItem, MenuList } from '@mui/material';
import { env } from 'env.mjs';
import Image from 'next/image';

import styles from './pageStyles';
import { MAHD_SPORTS_ACADEMY_ID } from 'helpers/constants';
import { CurrentUserWithRole } from 'server/context';

interface Props {
  currentUser: CurrentUserWithRole;
  isPerformanceLabPage: boolean;
}

const ClubLogo = ({ currentUser, isPerformanceLabPage }: Props) => (
  <MenuList
    sx={{
      ...styles.logo,
      backgroundColor: isPerformanceLabPage
        ? 'white'
        : `${currentUser?.club.color}`,
    }}
  >
    <MenuItem disableRipple sx={styles.clubLogoMenuItem}>
      {currentUser?.club.logo && (
        <Image
          src={`${env.NEXT_PUBLIC_CALCEY_S3_BUCKET_URL}/${currentUser?.club.logo}`}
          alt="logo"
          width={
            currentUser?.club.activeClubId === MAHD_SPORTS_ACADEMY_ID
              ? '60'
              : '44'
          }
          height={
            currentUser?.club.activeClubId === MAHD_SPORTS_ACADEMY_ID
              ? '60'
              : '44'
          }
        />
      )}
    </MenuItem>
  </MenuList>
);

export default ClubLogo;
