export const playersPerPage = 4;
export const firstRank = 1;
export const firstElementIndex = 0;
export const tableRowLimit = 5;
export const initialIndex = 0;
export const defaultStringValue = '';

export const gridStyles = {
  borderLeft: '1px dashed #8c8c8c',
  pl: 12,
  '@media (max-width: 1200px)': {
    borderLeft: 'none',
  },
  '@media (max-width: 900px)': {
    pl: 0,
  },
};

export const dropDownStyles = {
  width: {
    xs: '75vw',
    sm: '40vw',
    md: '20vw',
    lg: '15vw',
    xl: '14vw',
  },
};
