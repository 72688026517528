import { trace } from '@opentelemetry/api';
import { PrismaClient } from 'db/generated/app';
import { env } from 'env.mjs';

export { Prisma as DB } from 'db/generated/app';

export const createPrismaClient = () => {
  if (typeof window !== 'undefined') {
    return undefined;
  } else {
    const prismaClientApp = new PrismaClient({
      log:
        env.NEXT_PUBLIC_NODE_ENV === 'development'
          ? [
              {
                level: 'query',
                emit: 'event',
              },
              'error',
              'warn',
            ]
          : [
              {
                level: 'query',
                emit: 'event',
              },
              'error',
            ],
    });

    // Commented out because spans not in the same trace as the other Prisma spans
    // prismaClientCC.$on('query', (query) => {
    //   console.log('Query: ' + query.query);
    //   console.log('Params: ' + query.params);
    //   console.log('Duration: ' + query.duration + 'ms');

    //   const span = trace
    //     .getTracer('control-centre')
    //     .startSpan('prisma:cc:meta')
    //     .setAttributes({
    //       query: query.query,
    //       params: query.params,
    //       duration: query.duration,
    //     });

    //   span.end();
    // });

    return prismaClientApp.$extends({
      query: {
        async $allOperations({ operation, model, args, query }) {
          return trace
            .getTracer('prisma')
            .startActiveSpan(
              `prisma:cc:${model}:${operation}`,
              async (span) => {
                span.setAttributes({
                  environment: env.NEXT_PUBLIC_ENVIRONMENT,
                  queryArgs: JSON.stringify(args),
                });

                const result = await query(args);

                span.end();

                return result;
              }
            );
        },
      },
    });
  }
};

const globalForPrisma = globalThis as unknown as {
  prisma: ReturnType<typeof createPrismaClient> | undefined;
};

/**
 * This is the Prisma Client used to interact with the control center database
 * directly. It is a singleton and should be used throughout the application.
 */
export const db = globalForPrisma.prisma ?? createPrismaClient()!;

if (env.NEXT_PUBLIC_NODE_ENV !== 'production') globalForPrisma.prisma = db;
