import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import LoadingButton from 'components/shared/Buttons/LoadingButton';
import { higherLevelScoutRoleIds } from 'helpers/getHighLevelScoutRoles';
import { getPlayerPositionsByRegion } from 'helpers/getPositionsByRegion';
import sortByField from 'helpers/sortObjectsByField';
import { trpc } from 'helpers/trpc';
import {
  putApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositions,
  useGetApiV2Positions,
  useGetApiV2ProclubsProClubIdScouts,
  useGetApiV2ProclubsProClubIdScoutsCount,
  V2PositionsQueriesListModelsPositionModel,
  V2ProClubsQueriesScoutsListModelsScoutModel,
} from 'orval/calceyAPI/reactQuery';

type PlayerInfo = {
  id: number;
  name: string;
  positionIds: number[];
};

interface Props {
  anchorEl: null | HTMLElement | HTMLButtonElement;
  handleClose: () => void;
  player: PlayerInfo;
}

const skeletonEntriesCount = 25;
const defaultSearchValue = '';

export const MenuItemFontStyles = {
  fontSize: '14px',
  padding: '10px 17px',
  lineHeight: '1',
  letterSpacing: 0,
};

const PlayerManagementDropdown = ({ anchorEl, handleClose, player }: Props) => {
  const open = Boolean(anchorEl);
  const [selectedPositions, setSelectedPositions] = useState<
    V2PositionsQueriesListModelsPositionModel[]
  >([]);
  const [selectedScout, setSelectedScout] =
    useState<V2ProClubsQueriesScoutsListModelsScoutModel | null>(null);
  const [positionModalOpen, setPositionModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState(defaultSearchValue);
  const isHigherLevelScout = useRef<Boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const { data: currentUser } = trpc.shared.currentUser.useQuery();

  const {
    data: activeClubScouts,
    refetch: refetchActiveClubScouts,
    isFetching: isActiveClubScoutsFetching,
  } = trpc.shared.getActiveClubScouts.useQuery(undefined, { enabled: false });

  const {
    data: scoutCount,
    isFetching: isScoutCountFetching,
    refetch: refetchScouts,
  } = useGetApiV2ProclubsProClubIdScoutsCount(currentUser?.club.activeClubId!, {
    query: { enabled: false },
    request: {
      headers: { Authorization: `Bearer ${currentUser?.user.accessToken}` },
    },
  });

  const { data: clubScouts, isFetching: isScoutsFetching } =
    useGetApiV2ProclubsProClubIdScouts(
      currentUser?.club.activeClubId!,
      {
        limit: scoutCount?.count && scoutCount.count > 0 ? scoutCount.count : 1,
      },
      {
        query: { enabled: Boolean(scoutCount) },
        request: {
          headers: { Authorization: `Bearer ${currentUser?.user.accessToken}` },
        },
      }
    );
  const { data: positions } = useGetApiV2Positions({
    request: {
      headers: {
        Authorization: `Bearer ${currentUser?.user.accessToken}`,
      },
    },
  });

  const isDataFetching =
    isScoutCountFetching || isScoutsFetching || isActiveClubScoutsFetching;

  const sortedClubScouts = useMemo(() => {
    if (isHigherLevelScout.current) {
      const sortedScouts = sortByField(clubScouts?.items ?? [], 'name');

      const filteredScouts = sortedScouts.filter((scout) =>
        scout.name?.toLowerCase().includes(searchInput.toLowerCase())
      );

      const activeScouts = filteredScouts.filter((scout) =>
        activeClubScouts?.includes(scout.id!)
      );

      return activeScouts;
    }

    return clubScouts?.items?.filter(
      (scout) => scout.id === currentUser?.user.scoutId
    );
  }, [
    activeClubScouts,
    clubScouts?.items,
    currentUser?.user.scoutId,
    searchInput,
  ]);

  const setDefaultPlayerPositions = useCallback(() => {
    setSelectedPositions(
      positions?.items?.filter((position) =>
        player.positionIds.includes(position.id!)
      ) ?? []
    );
  }, [player.positionIds, positions?.items]);

  const addPlayerToScoutAppWatchList = async (
    scout: V2ProClubsQueriesScoutsListModelsScoutModel,
    positionsList: V2PositionsQueriesListModelsPositionModel[]
  ) => {
    setLoading(true);

    const positionIds = {
      positionIds: positionsList.map((position) => position.id!),
    };

    try {
      await putApiV2ScoutsScoutIdFollowingPlayersPlayerIdPositions(
        scout.id!,
        player.id,
        positionIds,
        {
          headers: {
            Authorization: `Bearer ${currentUser?.user.accessToken}`,
          },
        }
      );

      enqueueSnackbar(
        `${player.name} has been added to ${scout.name} watchlist`,
        {
          variant: 'success',
        }
      );

      onClose();

      setPositionModalOpen(false);
    } catch {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }

    setDefaultPlayerPositions();

    setLoading(false);
  };

  const addPlayerToList = (
    scout: V2ProClubsQueriesScoutsListModelsScoutModel
  ) => {
    if (selectedPositions.length === 0) {
      setPositionModalOpen(true);

      return;
    }

    addPlayerToScoutAppWatchList(scout, selectedPositions!);
  };

  const onClose = () => {
    setSearchInput(defaultSearchValue);
    handleClose();
  };

  const customStyles = {
    positionModalText: {
      px: 6,
    },
    dialogActions: {
      mt: 2,
    },
    autocompleteSkeleton: {
      height: '40px',
    },
    scoutListSkeletonBox: {
      px: 4,
    },
    scoutListSkeleton: {
      lineHeight: 2,
    },
    menu: {
      maxHeight: '75vh',
    },
    textField: {
      py: 2,
    },
    menuPaper: {
      backgroundColor: '#F6F6F6',
      px: 2,
      minWidth: '20vw',
    },
    searchIcon: {
      color: 'black',
    },
  };

  const positionModal = (
    <Dialog
      open={positionModalOpen}
      onClose={() => setPositionModalOpen(false)}
      fullWidth
    >
      <DialogTitle>Add Position</DialogTitle>

      <Typography sx={customStyles.positionModalText}>
        {player.name} does not have position. Please select a position
      </Typography>

      <DialogContent>
        {positions?.items ? (
          <>
            <Autocomplete
              multiple
              disableCloseOnSelect
              value={selectedPositions!}
              options={getPlayerPositionsByRegion(positions.items)}
              getOptionLabel={(option) => option.name!}
              onChange={(_, value) => {
                setSelectedPositions(value);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                  }}
                  label="Position"
                />
              )}
            />

            <DialogActions sx={customStyles.dialogActions}>
              <Button
                variant="secondary"
                onClick={() => setPositionModalOpen(false)}
              >
                Cancel
              </Button>

              <LoadingButton
                label="Add"
                disabled={selectedPositions.length === 0}
                loading={loading}
                loadingLabel="Adding"
                variant="primary"
                onClick={() =>
                  addPlayerToScoutAppWatchList(
                    selectedScout!,
                    selectedPositions!
                  )
                }
              />
            </DialogActions>
          </>
        ) : (
          <Skeleton
            variant="rectangular"
            sx={customStyles.autocompleteSkeleton}
          />
        )}
      </DialogContent>
    </Dialog>
  );

  const scoutListSkeleton = (
    <Box sx={customStyles.scoutListSkeletonBox}>
      {Array.from({ length: skeletonEntriesCount }).map((_, index) => (
        <Skeleton
          key={index}
          variant="text"
          sx={customStyles.scoutListSkeleton}
        />
      ))}
    </Box>
  );

  useEffect(() => {
    if (currentUser?.user.roleId) {
      const higherLevelScoutValue = higherLevelScoutRoleIds.includes(
        currentUser.user.roleId
      );

      isHigherLevelScout.current = higherLevelScoutValue;
    }
  }, [currentUser]);

  useEffect(() => {
    setDefaultPlayerPositions();
  }, [setDefaultPlayerPositions]);

  useEffect(() => {
    refetchActiveClubScouts();
    refetchScouts();
  }, [refetchScouts, refetchActiveClubScouts]);

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        sx={customStyles.menu}
        PaperProps={{
          sx: customStyles.menuPaper,
        }}
      >
        {isHigherLevelScout.current && (
          <Box sx={customStyles.textField}>
            <TextField
              size="small"
              placeholder="Search scouts"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={customStyles.searchIcon} />
                  </InputAdornment>
                ),
              }}
              onKeyDown={(event) => event.stopPropagation()}
              onChange={(event) => setSearchInput(event.target.value)}
            />
          </Box>
        )}

        {isDataFetching
          ? scoutListSkeleton
          : sortedClubScouts &&
            sortedClubScouts.map((scout, index) => (
              <>
                <MenuItem
                  key={index}
                  onClick={(event) => {
                    event.stopPropagation();
                    addPlayerToList(scout);
                    setSelectedScout(scout);
                  }}
                >
                  <Stack direction="row" alignItems="center" spacing={4}>
                    <Avatar src={scout.profileImage?.thumbnail ?? undefined} />

                    <Typography variant="h3" mb={0}>
                      {scout.name}
                    </Typography>
                  </Stack>
                </MenuItem>
              </>
            ))}
      </Menu>
      {positionModal}
    </>
  );
};

export default PlayerManagementDropdown;
