import { Divider, Skeleton, Stack } from '@mui/material';

import { playersPerPage } from 'components/pages/HomeContent/pageHelpers';

const skeletonButtonCount = 8;
const skeletonLenght = 5;

const customStyles = {
  playerSkeletonStack: {
    px: 1,
  },
  playerRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  playerSkeletonInnerStack: {
    py: 3,
    alignItems: 'center',
  },
  avatarSkeleton: {
    width: 50,
    height: 50,
  },
  nameSkeleton: {
    width: '15vw',
    height: 25,
  },
  skeletonArrow: {
    width: 25,
    height: 25,
  },
  paginationStack: {
    px: 6,
    pt: 2,
    pb: 4,
    width: '90%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 12,
  },
  paginationSkeletonText: {
    width: '30%',
    height: 30,
  },
  paginationNumbersSkeleton: {
    mt: 2,
  },
  buttonSkeleton: {
    width: '5vw',
    height: 40,
  },
};

export const playerListSkeleton = (
  <Stack divider={<Divider />} sx={customStyles.playerSkeletonStack}>
    {Array.from({ length: playersPerPage }).map((_, index) => (
      <Stack key={index} direction="row" sx={customStyles.playerRow}>
        <Stack
          direction="row"
          spacing={4}
          sx={customStyles.playerSkeletonInnerStack}
        >
          <Skeleton variant="circular" sx={customStyles.avatarSkeleton} />

          <Skeleton variant="text" sx={customStyles.nameSkeleton} />
        </Stack>

        <Skeleton variant="circular" sx={customStyles.skeletonArrow} />
      </Stack>
    ))}
  </Stack>
);

export const paginationSkeleton = (
  <Stack sx={customStyles.paginationStack}>
    <Skeleton variant="text" sx={customStyles.paginationSkeletonText} />

    <Stack
      direction="row"
      spacing={2}
      sx={customStyles.paginationNumbersSkeleton}
    >
      {Array.from({ length: skeletonButtonCount }).map((_, index) => (
        <Skeleton
          key={index}
          variant="circular"
          sx={customStyles.skeletonArrow}
        />
      ))}
    </Stack>
  </Stack>
);

export const playerSessionListSkeleton = (
  <Stack divider={<Divider />} sx={customStyles.playerSkeletonStack}>
    {Array.from({ length: skeletonLenght }).map((_, index) => (
      <Stack key={index} direction="row" sx={customStyles.playerRow}>
        <Skeleton variant="text" sx={customStyles.nameSkeleton} />

        <Stack
          direction="row"
          spacing={4}
          sx={customStyles.playerSkeletonInnerStack}
        >
          <Skeleton variant="text" sx={customStyles.buttonSkeleton} />

          <Skeleton variant="text" sx={customStyles.buttonSkeleton} />
        </Stack>
      </Stack>
    ))}
  </Stack>
);
