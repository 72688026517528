import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  PaletteMode,
  Typography,
} from '@mui/material';
import { env } from 'env.mjs';
import Image from 'next/image';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

import { getIconStyle } from './helper';
import { MenuItemFontStyles } from 'components/shared/PlayerManagementDropdown';
import { MAHD_SPORTS_ACADEMY_ID } from 'helpers/constants';
import { trpc } from 'helpers/trpc';

interface Props {
  isPerformanceLabPage: boolean;
  theme: PaletteMode;
}

const Clubs = ({ isPerformanceLabPage, theme }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = useState<null | Element>(null);

  const { data: currentUser, refetch: refetchCurrentUser } =
    trpc.shared.currentUser.useQuery();

  const switchClubMutation = trpc.shared.switchActiveClub.useMutation();

  const hasInactiveClubs = Boolean(
    currentUser && currentUser?.club.inactiveClubs.length > 0
  );
  const open = Boolean(anchorEl);

  const changeClub = async (clubId: number) => {
    enqueueSnackbar('Switching club...', { variant: 'info' });

    try {
      await switchClubMutation.mutateAsync({
        clubId,
      });

      enqueueSnackbar('Successful club switch', { variant: 'success' });
    } catch {
      enqueueSnackbar('Unsuccessful club switch', { variant: 'error' });
    }

    await refetchCurrentUser();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!hasInactiveClubs) {
    return null;
  }

  return (
    <Box>
      <Button
        variant="text"
        onClick={(event) => setAnchorEl(event.currentTarget!)}
        sx={getIconStyle(theme, isPerformanceLabPage)}
      >
        <Image
          src={
            currentUser?.club.activeClubId === MAHD_SPORTS_ACADEMY_ID
              ? `/images/sidebar/logos/scai.png`
              : `${env.NEXT_PUBLIC_CALCEY_S3_BUCKET_URL}/${currentUser?.club.logo}`
          }
          alt="club badge"
          width={
            currentUser?.club.activeClubId === MAHD_SPORTS_ACADEMY_ID ? 60 : 25
          }
          height={
            currentUser?.club.activeClubId === MAHD_SPORTS_ACADEMY_ID ? 15 : 25
          }
        />

        <ArrowDropDownIcon sx={getIconStyle(theme, isPerformanceLabPage)} />
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
      >
        {currentUser?.club.inactiveClubs.map((inactiveClub) => (
          <MenuItem
            key={Number(inactiveClub.id)}
            onClick={() => changeClub(Number(inactiveClub.id))}
            sx={MenuItemFontStyles}
          >
            <Image
              src={`${env.NEXT_PUBLIC_CALCEY_S3_BUCKET_URL}/${inactiveClub.logo}`}
              alt="badge"
              width={20}
              height={20}
            />

            <Typography ml={3}>{inactiveClub.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default Clubs;
