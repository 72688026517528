interface AffiliationCode {
  id: number;
  name: string;
  entryCode: string;
}

export interface AffiliationCodes {
  affiliationCodes: AffiliationCode[];
}

export const DrillMetricTypes = {
  singleDrill: 'single_drill',
  drillsAverage: 'drills_average',
  completedDrillsAverage: 'completed_drills_average',
} as const;

export type DrillMetricType =
  typeof DrillMetricTypes[keyof typeof DrillMetricTypes];
