import { Button, SxProps, Theme } from '@mui/material';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import MaterialUISwitch from './MaterialUISwitch';
import { ThemeMode } from 'components/MUI/theme';
import SidebarMenuJSON from 'components/shared/Sidebar/SidebarMenuJSON';
import { themeState, themeSwitchState } from 'state/atoms';

const disabledCursorStyles = {
  '.MuiButtonBase-root': {
    cursor: 'not-allowed',
  },
} as SxProps<Theme>;

const flattenedMenuLinks = SidebarMenuJSON.flatMap(({ submenu }) => submenu);

const ThemeSwitcher = () => {
  const [theme, setTheme] = useRecoilState(themeState);
  const setThemeSwitched = useSetRecoilState(themeSwitchState);
  const router = useRouter();

  const currentPageLink = flattenedMenuLinks.find(
    ({ link }) => link === router.pathname
  );

  useEffect(() => {
    const changeTheme = (theme: ThemeMode) => {
      setThemeSwitched(true);
      setTheme(theme);
    };

    if (theme === 'light') {
      changeTheme('light');
    } else {
      changeTheme('dark');
    }
  }, [theme, setTheme, setThemeSwitched]);

  const toggleTheme = () => {
    return theme === 'light' ? setTheme('dark') : setTheme('light');
  };

  const pageDoesNotSupportBothThemes =
    currentPageLink?.supportsBothThemes !== undefined &&
    currentPageLink?.supportsBothThemes === false;

  const unsupportedTheme =
    pageDoesNotSupportBothThemes && theme === 'light' ? 'dark' : 'light';

  return (
    <Button
      variant="text"
      onClick={toggleTheme}
      disabled={pageDoesNotSupportBothThemes}
      sx={pageDoesNotSupportBothThemes ? disabledCursorStyles : {}}
      title={
        pageDoesNotSupportBothThemes
          ? `${unsupportedTheme} theme is not available for this page, yet`
          : ''
      }
    >
      <MaterialUISwitch checked={theme === 'dark'} />
    </Button>
  );
};

export default ThemeSwitcher;
