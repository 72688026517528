import { Box, useTheme } from '@mui/material';
import { useRouter } from 'next/router';

import { brandonFontFamily } from 'components/MUI/typography';
import { getPerformanceLabPagePaths } from 'helpers/getPerformanceLabPages';

interface Props {
  page: number;
  lastPage: number;
}

const ShowingPage = ({ page, lastPage }: Props) => {
  const router = useRouter();
  const theme = useTheme();

  const isPerformanceLabPage = getPerformanceLabPagePaths().includes(
    router.pathname
  );

  const customStyles = {
    container: {
      textTransform: isPerformanceLabPage ? 'uppercase' : 'inherit',
      color: theme.palette.mode === 'dark' ? 'white' : 'black',
      fontFamily: isPerformanceLabPage ? brandonFontFamily : 'inherit',
      fontWeight: !isPerformanceLabPage ? 'bold' : 'inherit',
      fontSize: isPerformanceLabPage ? 14 : 'inherit',
    },
  };

  return (
    <Box sx={customStyles.container}>
      Showing page {page} of {lastPage || 1}
    </Box>
  );
};

export default ShowingPage;
