import {
  Button,
  ButtonProps,
  CircularProgress,
  CircularProgressProps,
  Typography,
} from '@mui/material';
import React from 'react';

import {
  brandonFontFamily,
  mainThemeBoldFontFamily,
} from 'components/MUI/typography';

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'text'
  | 'outlined'
  | 'contained';

type ButtonSize = 'small' | 'medium' | 'large';

interface LoadingButtonProps extends ButtonProps {
  loading: boolean;
  label: string;
  loadingLabel?: string;
  variant?: ButtonVariant;
  size?: ButtonSize;
  circularProgressProps?: CircularProgressProps;
  isPerformanceLabPage?: boolean;
}

const LoadingButton = ({
  loading,
  label,
  loadingLabel,
  size = 'small',
  variant = 'primary',
  isPerformanceLabPage = false,
  circularProgressProps,
  ...buttonProps
}: LoadingButtonProps) => {
  const getCircularProgressColor = () => {
    if (isPerformanceLabPage && variant === 'outlined') {
      return 'primary.main';
    }

    if (!isPerformanceLabPage && variant === 'secondary') {
      return 'black';
    }

    return 'white';
  };

  return (
    <Button
      className="w-full"
      disabled={loading}
      variant={variant}
      size={size}
      {...buttonProps}
    >
      {loading ? (
        <>
          <CircularProgress
            size={isPerformanceLabPage ? 16 : 20}
            sx={{ mr: 2, color: getCircularProgressColor() }}
            {...circularProgressProps}
          />
          <Typography
            fontFamily={
              isPerformanceLabPage ? brandonFontFamily : mainThemeBoldFontFamily
            }
          >
            {loadingLabel ?? label}
          </Typography>
        </>
      ) : (
        label
      )}
    </Button>
  );
};

export default LoadingButton;
