export default function sortByField<T>(
  list: T[],
  field: keyof T,
  ascending: boolean = true
): T[] {
  const sorted = [...list];

  sorted.sort((a, b) => {
    const version1 = `${a[field]}`;
    const version2 = `${b[field]}`;

    if (ascending) {
      return version1.localeCompare(version2, undefined, {
        numeric: true,
        sensitivity: 'base',
      });
    } else {
      return version2.localeCompare(version1, undefined, {
        numeric: true,
        sensitivity: 'base',
      });
    }
  });
  return sorted;
}
