import { PageLink } from './SidebarMenuJSON';
import { AiSCOUTRole, convertRoleIDtoAiSCOUTRoleType } from 'server/context';

export const isLocalhost = () => {
  return Boolean(window.location.hostname === 'localhost');
};

export const getUserRoleById = (
  roles: AiSCOUTRole[],
  currentUserRoleId: number | undefined
) =>
  roles.find(
    (role) => role === convertRoleIDtoAiSCOUTRoleType(currentUserRoleId!)
  );

export const isUpcomingPage = (pageLink: PageLink) => pageLink.link === '#';

export const isExistingPage = (pageLink: PageLink) => pageLink.link !== '#';
