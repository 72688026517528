import { CreateReactUtils } from '@trpc/react-query/shared';

import {
  ClubSettingFilter,
  fullAccessFilter,
  ValueFieldOption,
} from 'components/shared/ControlCentreConfig/helpers';
import { AISCOUT_CLUB_ID, errorMessages } from 'helpers/constants';
import convertToSentenceCase from 'helpers/convertToSentenceCase';
import { InferTRPCOutputTypes, SSRContext } from 'helpers/trpc';
import { getApiV2Proclubs } from 'orval/calceyAPI/reactQuery';
import { CurrentUser } from 'server/context';
import { AppRouter } from 'server/routers';
import {
  DrillMetricType,
  DrillMetricTypes,
} from 'server/routers/controlCentreConfiguration/types';
import { FilterType } from 'server/routers/recruitmentCenter/types';

export const defaultValue = '';
const defaultLimit = 1000;

export type DrillMetricGraph =
  InferTRPCOutputTypes['controlCentreConfiguration']['getDrillMetricGraphs']['drillMetricGraphs'][number];

export type DrillMetric =
  InferTRPCOutputTypes['controlCentreConfiguration']['getDrillMetrics']['drillMetrics'][number];

export type AllValueOptions = {
  affiliationCodes: ValueFieldOption[];
  countries: ValueFieldOption[];
  proClubs: ValueFieldOption[];
};

export type Rule =
  InferTRPCOutputTypes['controlCentreConfiguration']['getRecruitmentCentreRuleEngineRules']['rules'][number];

export type ControlCentreConfigurationDrill =
  InferTRPCOutputTypes['controlCentreConfiguration']['getProClubMetricDrills']['drills'][number];

export type AgeGroupCalculationMethod =
  typeof ageGroupCalculationMethods[number];

export const commonStyles = {
  field: {
    width: 300,
    backgroundColor: 'white',
  },
  dropdown: {
    minWidth: 250,
    backgroundColor: 'white',
  },
  skeleton: {
    minWidth: 250,
    height: 40,
    borderRadius: 2,
  },
};

export const ageGroupCalculationMethods = [
  'uk_school_year',
  'calendar_year',
] as const;

export const confirmOptions = {
  okButtonText: 'Remove',
  variant: 'confirm' as const,
  okButtonProps: {
    variant: 'error' as const,
  },
  cancelButtonProps: {
    variant: 'secondary' as const,
  },
  showLoaderOnOk: true,
};

export const getValueFieldOptions = async (
  trpcContext: CreateReactUtils<AppRouter, SSRContext>
) => {
  const defaultAllValueOptions: AllValueOptions = {
    affiliationCodes: [],
    countries: [],
    proClubs: [],
  };

  try {
    const allCodes = await trpcContext.shared.getAllAffiliationCodes.fetch();

    defaultAllValueOptions.affiliationCodes =
      allCodes.affiliationCodes.map((code) => ({
        label: code.name,
        id: code.id,
      })) ?? [];

    const adminAccessToken =
      await trpcContext.shared.getAdminAccessToken.fetch();

    const headers = { Authorization: `Bearer ${adminAccessToken}` };

    const allClubs = await getApiV2Proclubs(
      { limit: defaultLimit },
      { headers }
    );

    defaultAllValueOptions.proClubs =
      allClubs?.items?.map((club) => ({
        label: club.name ?? defaultValue,
        id: club.id!,
      })) ?? [];

    const allCountries = await trpcContext.shared.getCountriesV3.fetch();

    defaultAllValueOptions.countries =
      allCountries?.items?.map((country) => ({
        label: country.name ?? defaultValue,
        id: country.id!,
      })) ?? [];

    return defaultAllValueOptions;
  } catch {
    return defaultAllValueOptions;
  }
};

export const validateFilter = (newFilter: ClubSettingFilter) => {
  const fieldValues = Object.values(newFilter);

  if (fieldValues.includes(defaultValue as FilterType)) {
    return false;
  }

  if (
    newFilter.filterType !== fullAccessFilter &&
    !Boolean(newFilter.filterValue)
  ) {
    return false;
  }

  return true;
};

export const getFilterError = (
  newFilter: ClubSettingFilter,
  existingFilters: Rule[]
) => {
  const isFilterExists = existingFilters.some(
    (data) =>
      data.comparisonOperator === newFilter.comparisonOperator &&
      data.filterType === newFilter.filterType &&
      data.logicalOperator === newFilter.logicalOperator &&
      data.filterValue === newFilter.filterValue?.id
  );

  if (isFilterExists) {
    return errorMessages.filterExists;
  }

  const isFullAccessFilterExists =
    newFilter.filterType === fullAccessFilter &&
    existingFilters.some((data) => data.filterType === fullAccessFilter);

  if (isFullAccessFilterExists) {
    return errorMessages.noFullAccessFilter;
  }

  return null;
};

export const getOptions = (
  filterType: FilterType,
  allValueOptions: AllValueOptions,
  currentUser: CurrentUser
) => {
  if (filterType === 'affiliation') {
    return allValueOptions.affiliationCodes;
  }

  if (
    filterType === 'pro_club_drills' &&
    currentUser?.club.activeClubId !== AISCOUT_CLUB_ID
  ) {
    return [
      { label: currentUser.club.name, id: currentUser.club.activeClubId },
    ];
  }

  if (filterType === 'pro_club_drills') {
    return allValueOptions.proClubs;
  }

  if (filterType === 'country') {
    return allValueOptions.countries;
  }

  return [];
};

export const options = [
  {
    metricType: DrillMetricTypes.singleDrill,
    name: 'Single Drill',
    description: `The Single Drill option only allows you to drag one available trial over to the Configure Option list. </br>Single Drill Formula Example: Drill Metric = [Drill]`,
    maxDrills: 1,
  },
  {
    metricType: DrillMetricTypes.drillsAverage,
    name: 'Drills Average',
    description:
      'The Drills Average option allows you to drag multiple available trials over to the Configure Option list. </br>Drills Average Formula Example: Drill Metric = ([Drill] + [Drill]) / [Number of selected drills]',
    maxDrills: null,
  },
  {
    metricType: DrillMetricTypes.completedDrillsAverage,
    name: 'Completed Drills Average',
    description:
      'The Completed Drills Average option allows you to drag multiple available trials over to the Configure Option list. </br>Completed Drills Average Formula Example: Drill Metric = ([Drill] + [Drill]) / [Number of completed selected drills]',
    maxDrills: null,
  },
] as const;

export const getDateTwoMonthsAgo = () => {
  const today = new Date();

  today.setMonth(today.getMonth() - 2);

  return today;
};

export const convertDrillMetricTypeToName = (
  metricType: DrillMetricType
): string => {
  // Split the input string by underscores
  const words = metricType.split('_');

  return convertToSentenceCase(words.join(' '));
};
