import {
  ComparisonOperator,
  FilterType,
  LogicalOperator,
} from 'server/routers/recruitmentCenter/types';

export type ValueFieldOption = {
  label: string;
  id: number;
};

export type ClubSettingFilter = {
  filterType: FilterType;
  comparisonOperator: ComparisonOperator;
  filterValue: ValueFieldOption | null;
  logicalOperator: LogicalOperator;
};

const defaultValue = '';
export const fullAccessFilter = 'full_access';
export const commonStyles = {
  dropdown: {
    minWidth: 250,
    backgroundColor: 'white',
  },
};

export const getFieldError = (
  name: keyof ClubSettingFilter,
  newFilter: ClubSettingFilter
) => {
  if (newFilter.filterType === fullAccessFilter && name === 'filterValue') {
    return defaultValue;
  }

  return Boolean(newFilter[name]) ? defaultValue : 'Required';
};

export interface Offsets {
  offsetLeft: number;
  offsetTop: number;
  offsetWidth: number;
  offsetHeight: number;
}
